var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      ref: "reg",
      attrs: {
        "status-icon": "",
        "label-width": "140px",
        "label-position": "top",
        model: _vm.formData,
        rules: _vm.rules,
      },
      nativeOn: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleLogin("reg")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "df m-b-5",
          staticStyle: { "flex-direction": "column" },
        },
        [
          _c("div", { staticClass: "m-b-5" }, [_vm._v("Имя")]),
          _c("IqInputForm", {
            attrs: {
              ownclass: "no-zoom",
              placeholder: "Введите имя",
              prop: "name",
              "input-width": "100%",
              rules: _vm.rules.name,
            },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "df m-b-5",
          staticStyle: { "flex-direction": "column" },
        },
        [
          _c("div", { staticClass: "m-b-5" }, [_vm._v("Фамилия")]),
          _c("IqInputForm", {
            attrs: {
              ownclass: "no-zoom",
              "input-width": "100%",
              placeholder: "Введите фамилию",
              prop: "surname",
              rules: _vm.rules.surname,
            },
            model: {
              value: _vm.formData.surname,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "surname", $$v)
              },
              expression: "formData.surname",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "df m-b-5",
          staticStyle: { "flex-direction": "column" },
        },
        [
          _c("div", { staticClass: "m-b-5" }, [_vm._v("Телефон")]),
          _c("IqInputForm", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "+7 (###) ###-##-##",
                expression: "'+7 (###) ###-##-##'",
              },
            ],
            attrs: {
              ownclass: "no-zoom",
              "input-width": "100%",
              placeholder: "Введите телефон",
              prop: "phone",
              rules: _vm.rules.phone,
            },
            model: {
              value: _vm.formData.phone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "phone", $$v)
              },
              expression: "formData.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "df m-b-5",
          staticStyle: { "flex-direction": "column" },
        },
        [
          _c("div", { staticClass: "m-b-5" }, [_vm._v("Придумайте пароль")]),
          _c("IqInputForm", {
            attrs: {
              ownclass: "no-zoom",
              "input-width": "100%",
              placeholder: "Введите пароль",
              prop: "password",
              rules: _vm.rules.password,
            },
            model: {
              value: _vm.formData.password,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "password", $$v)
              },
              expression: "formData.password",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "df m-b-5",
          staticStyle: { "flex-direction": "column" },
        },
        [
          _c("div", { staticClass: "m-b-5" }, [_vm._v("Повторите пароль")]),
          _c("IqInputForm", {
            attrs: {
              ownclass: "no-zoom",
              "input-width": "100%",
              placeholder: "Введите пароль",
              prop: "passwordCheck",
              rules: _vm.rules.passwordCheck,
            },
            model: {
              value: _vm.formData.passwordCheck,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "passwordCheck", $$v)
              },
              expression: "formData.passwordCheck",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "df m-b-5",
          staticStyle: { "flex-direction": "column" },
        },
        [
          _c(
            "MainCheckbox",
            {
              attrs: { prop: "confidentiality" },
              model: {
                value: _vm.formData.privacyPolicy,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "privacyPolicy", $$v)
                },
                expression: "formData.privacyPolicy",
              },
            },
            [
              _c(
                "div",
                { staticClass: "reg_form-CheckboxContent" },
                [
                  _vm._v(" Я согласен с "),
                  _c(
                    "el-link",
                    {
                      staticClass: "reg_form-CheckboxLink",
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("show-AgreeDialog")
                        },
                      },
                    },
                    [_vm._v(" политикой конфиденциальности ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "iq-button",
            {
              class: { fullWidthMedium: _vm.isMobile },
              attrs: { color: "primary" },
              on: {
                onClick: function ($event) {
                  return _vm.handleReg("reg")
                },
              },
            },
            [_vm._v(" Зарегистрироваться ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }