<template>
  <el-form
    ref="reg"
    v-loading="isLoading"
    status-icon
    label-width="140px"
    label-position="top"
    :model="formData"
    :rules="rules"
    @keyup.enter.native="handleLogin('reg')"
  >
    <div class="df m-b-5" style="flex-direction: column">
      <div class="m-b-5">Имя</div>
      <IqInputForm
        v-model="formData.name"
        ownclass="no-zoom"
        placeholder="Введите имя"
        prop="name"
        input-width="100%"
        :rules="rules.name"
      />
    </div>
    <div class="df m-b-5" style="flex-direction: column">
      <div class="m-b-5">Фамилия</div>
      <IqInputForm
        v-model="formData.surname"
        ownclass="no-zoom"
        input-width="100%"
        placeholder="Введите фамилию"
        prop="surname"
        :rules="rules.surname"
      />
    </div>
    <div class="df m-b-5" style="flex-direction: column">
      <div class="m-b-5">Телефон</div>
      <IqInputForm
        v-model="formData.phone"
        v-mask="'+7 (###) ###-##-##'"
        ownclass="no-zoom"
        input-width="100%"
        placeholder="Введите телефон"
        prop="phone"
        :rules="rules.phone"
      />
    </div>
    <div class="df m-b-5" style="flex-direction: column">
      <div class="m-b-5">Придумайте пароль</div>
      <IqInputForm
        v-model="formData.password"
        ownclass="no-zoom"
        input-width="100%"
        placeholder="Введите пароль"
        prop="password"
        :rules="rules.password"
      />
    </div>
    <div class="df m-b-5" style="flex-direction: column">
      <div class="m-b-5">Повторите пароль</div>
      <IqInputForm
        v-model="formData.passwordCheck"
        ownclass="no-zoom"
        input-width="100%"
        placeholder="Введите пароль"
        prop="passwordCheck"
        :rules="rules.passwordCheck"
      />
    </div>
    <div class="df m-b-5" style="flex-direction: column">
      <MainCheckbox v-model="formData.privacyPolicy" prop="confidentiality">
        <div class="reg_form-CheckboxContent">
          Я согласен с
          <el-link
            class="reg_form-CheckboxLink"
            :underline="false"
            @click="$emit('show-AgreeDialog')"
          >
            политикой конфиденциальности
          </el-link>
        </div>
      </MainCheckbox>
    </div>

    <el-form-item>
      <iq-button
        :class="{ fullWidthMedium: isMobile }"
        color="primary"
        @onClick="handleReg('reg')"
      >
        Зарегистрироваться
      </iq-button>
    </el-form-item>
  </el-form>
</template>

<script>
import regMixin from '@/views/login/data/regMixin'

export default {
  name: 'RegistrationFormMobile',
  mixins: [regMixin],
}
</script>

<style lang="sass" scoped>
@import '../data/regFormStyles.sass'
</style>
